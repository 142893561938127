.rent__container {
  display: flex;
  margin-top: 20px;
  justify-content: flex-start;
  height: 100%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;

  .rent__image {
    img {
      width: 388px;
      height: 271px;
      border-radius: 8px;
      @media only screen and (max-width: 900px) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .rent__info {
    margin-right: 175px;
    margin-left: 20px;
  }

  .rent__price {
    display: flex;

    h1 {
      font-size: 23px;
      text-align: center;
      color: #1745e0;
    }

    p {
      font-size: 15px;
      text-align: center;
      margin-left: 10px;
      margin-top: 5px;
    }

    @media only screen and (max-width: 1494px) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;

    .rent__info {
      margin-left: 0px;
      margin-top: 20px;
      margin-right: 0px;
    }
    .rent__price {
      margin-left: 0px;
      margin-top: 20px;
    }
  }
}

.rent__pagination {
  float: right;
  margin-top: 20px;
}
