.login__left {
  img {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
}

.login__right {
  text-align: left;

  .login__right__title {
    color: #0058ff;
    font-family: "Inter", sans-serif;
    font-weight: 800;
    font-size: 50px;
  }

  .login__input__container {
    margin-top: -15px;
  }

  .login__input {
    margin-left: 5px;
    width: 85%;
    margin-right: 5px;
    border: none;
    outline: none;

    @media screen and (max-width: 1480px) {
      width: 75%;
    }

    @media screen and (max-width: 1034px) {
      width: 60%;
    }

    @media screen and (max-width: 800px) {
      width: 80%;
    }
  }

  .login__remember__container {
    display: flex;
    align-content: center;

    .login__forgot {
      color: #48a3ff;
      font-size: 15px;
      margin-left: 5px;
      cursor: pointer;

      @media screen and (max-width: 1480px) {
        margin-left: -70px;
      }

      @media screen and (max-width: 1034px) {
        margin-left: -70px;
      }

      @media screen and (max-width: 800px) {
        margin-left: 10px;
      }
    }
  }

  .login__button__container {
    align-content: center;
    margin-top: 20px;

    .login__btn {
      width: 100%;
      border-radius: 34px;
    }
  }

  .login__bottom__text {
    font-size: 15px;
    text-align: center;
    margin-top: 20px;
  }

  .form-group {
    padding: 10px;
    width: 100%;
    height: 64px;
    border: 2px solid;
    outline: none;
    border-radius: 16px;
    margin-bottom: 25px;
  }
  .form-group > label {
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    top: -24px;
    left: 20px;
    background-color: white;
  }

  .form-group > input {
    border: none;
    width: 100%;
    outline: none;
  }
}
