.register__left {
  img {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
}

.register__right {
  text-align: left;

  .register__right__title {
    color: #7300fa;
    font-family: "Inter", sans-serif;
    font-weight: 800;
    font-size: 50px;
  }

  .register__input__container {
    margin-top: -15px;
  }

  .register__input {
    margin-left: 5px;
    width: 85%;
    margin-right: 5px;
    border: none;
    outline: none;
  }

  .register__remember__container {
    display: flex;
    align-content: center;
    margin: 10px;

    .register__forgot {
      color: #48a3ff;
      font-size: 15px;
    }
  }

  .register__button__container {
    align-content: center;
    margin-left: 10px;
    margin-top: 20px;

    .register__btn {
      width: 100%;
      border-radius: 34px;
      margin-bottom: 20px;
      background-color: #7300fa;
      border: 0px;
    }
  }

  .register__bottom__text {
    font-size: 15px;
    text-align: center;
    margin-top: 20px;
  }

  .form-group {
    padding: 10px;
    width: 100%;
    border: 2px solid;

    outline: none;
    border-radius: 16px;
    margin-bottom: 25px;
  }
  .form-group > label {
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    top: -24px;
    left: 20px;
    background-color: white;
  }

  .form-group > input {
    border: none;
    width: 100%;
    outline: none;
  }
}
