@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

App {
  width: 100vw;
  height: 100vh;
}

.app__container {
  max-width: 100rem;
  margin: auto;
  padding-top: 20px;
  text-align: center;
}

@media only screen and (max-width: 1494px) {
  .app__container {
    text-align: left;
  }
}
