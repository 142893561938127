.map__info__left {
  background: linear-gradient(
    164deg,
    rgba(249, 92, 187, 1) 0%,
    rgba(66, 113, 221, 1) 100%
  );
  height: 992px;
  text-align: center;
  position: relative;

  h1 {
    top: 50%;
    position: absolute;
    color: white;
    font-weight: 600;
  }

  p {
    top: 95%;
    color: white;
    font-weight: 300;
    position: absolute;
  }
}

.map__info__right {
  text-align: left;
  margin-top: 50px;
  margin-left: 20px;

  .map__right__title {
    border-left: 6px solid #2c4dff;
    border-radius: 2px;
    padding-left: 6px;
    font-size: 12px;

    h1 {
      font-size: 24px;
      font-weight: 500;
    }
  }

  .map__btn {
    width: 158px;
    height: 57px;
    border-radius: 15px;
    float: right;
    margin-top: 50px;
  }
}

// Mobile Responsiveness
@media only screen and (max-width: 768px) {
  .map__info__left {
    display: none;
  }

  .map__info__right {
    margin-top: 0px;
  }

  .map__info__right .map__btn {
    width: 95%;
    float: none;
  }
}
