.sell__info__left {
  background: linear-gradient(
    164deg,
    rgba(249, 92, 187, 1) 0%,
    rgba(66, 113, 221, 1) 100%
  );
  height: 992px;
  text-align: center;
  position: relative;

  h1 {
    top: 50%;
    position: absolute;
    color: white;
    font-weight: 600;
  }

  p {
    top: 95%;
    color: white;
    font-weight: 300;
    position: absolute;
  }
}

.sell__info__right {
  text-align: left;
  margin-top: 50px;
  margin-left: 20px;

  .sell__right__title {
    border-left: 6px solid #2c4dff;
    border-radius: 2px;
    padding-left: 6px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 24px;
  }

  .form-group {
    padding: 10px;
    width: 333px;
    height: 64px;
    border: 2px solid;
    margin: 10px;
    outline: none;
    border-radius: 16px;
    margin-bottom: 50px;
  }
  .form-group > label {
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    top: -24px;
    left: 20px;
    background-color: white;
  }

  .form-group > input {
    border: none;
    width: 100%;
    outline: none;
  }

  .sell__btn {
    width: 158px;
    height: 57px;
    border-radius: 15px;
    float: right;
    margin-top: 50px;
  }
}

// Mobile Responsiveness
@media only screen and (max-width: 768px) {
  .sell__info__left {
    display: none;
  }

  .sell__info__right {
    margin-top: 0px;
  }

  .sell__info__right .sell__btn {
    width: 95%;
    float: none;
  }

  .sell__info__right .form-group {
    margin-left: 0px;
  }
}
